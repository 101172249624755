<script setup lang="ts">
const navigation = [
  { name: 'Angebote', href: '/' },
  { name: 'Regionen', href: '/regionen' },
  { name: 'Kunstszene', href: '/kunstszene' },
  { name: 'Künstler:innen', href: '/kuenstler-innen' },
] as const

const route = useRoute()
const scrolledToTop = ref(true)
const invert = computed(
  () => (scrolledToTop.value && route.meta.layoutProps?.invertNavbar) ?? false,
)

const user = useUser()

const supabase = useSupabase()
function signOut() {
  void supabase.auth.signOut()
}

function onScroll(event: Event) {
  scrolledToTop.value = (event.target as any)?.scrollingElement.scrollTop <= 10
}

onMounted(() => {
  // eslint-disable-next-line github/prefer-observers
  window.addEventListener('scroll', onScroll, {
    capture: true,
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll, {
    capture: true,
  })
})
</script>

<template>
  <AlPadding
    side="right"
    class="relative py-8 transition-colors duration-500 lg:pl-10"
    :class="!scrolledToTop && 'bg-white'"
  >
    <nav class="flex items-center justify-between gap-8 max-lg:flex-col">
      <AlLink href="/" class="flex shrink-0 items-center">
        <!-- <AlSVGSrc src="/logo_text_brush.svg" class="h-9 text-al-gray" :class="invert && 'invert'" /> -->
        <img
          src="/logo_with_subtitle.png"
          alt="atelierluft Logo"
          class="h-9"
          :class="invert && 'lg:invert'"
        />
      </AlLink>
      <ul
        class="flex flex-wrap items-center justify-center gap-x-8 gap-y-4 font-extrabold max-sm:flex-col lg:justify-end"
      >
        <li v-for="nav in navigation" :key="nav.href" class="group relative flex w-min text-lg">
          <div class="absolute inset-x-0 mt-0.5 h-6 group-hover:bg-al-primary" />
          <AlLink
            :href="{ path: nav.href, query: { anzeige: route.query.anzeige } }"
            class="relative text-shadow-white group-hover:text-white"
            :class="invert && 'lg:text-white'"
          >
            {{ nav.name }}
          </AlLink>
        </li>
        <ClientOnly>
          <template v-if="user">
            <ul
              class="flex flex-wrap items-center justify-center gap-x-8 gap-y-4 max-sm:flex-col lg:justify-end"
            >
              <li v-if="user.user_metadata.role === 'admin'">
                <UButton label="Adminbereich" icon="i-ph-lock" to="/admin" />
              </li>
              <li>
                <UButton label="Meine Buchungen" icon="i-ph-calendar" to="/buchung/uebersicht" />
              </li>
              <li>
                <UButton
                  label="Abmelden"
                  variant="soft"
                  icon="i-ph-sign-out"
                  @click="() => signOut()"
                />
              </li>
            </ul>
          </template>
        </ClientOnly>
      </ul>
    </nav>
  </AlPadding>
  <AlPadding side="right">
    <hr class="h-0.5 w-full" :class="invert ? 'border-white' : 'border-black'" />
  </AlPadding>
</template>
