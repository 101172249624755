<script setup lang="ts">
defineSlots<{
  default: void
}>()

const route = useRoute()
const solidHeader = computed(() => route.meta.layoutProps?.solidHeader)
</script>

<template>
  <div class="w-full">
    <div class="top-0 z-40 w-full" :class="[!solidHeader && 'lg:fixed']">
      <AlNavbar />
    </div>

    <div class="flex w-full flex-col gap-y-28" :class="[solidHeader && 'mt-8']">
      <slot />
    </div>
    <AlFooter class="mt-64" />
  </div>
</template>
